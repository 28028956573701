<template>
    <div style="padding-top: 20px">
        <div class="text-center" style="font-size: 22px; font-weight: bold; color: #444;">
            Welcome {{bages.cardiologistName}}
        </div>
        <div style="height: 20px;"></div>
        <div class="container-fluid">
            <div class="row">
                
                <div @click="$router.push('/cardiologist/reports/specific')" class="col-sm-6 col-lg-3">
                    <div class="card text-white bg-info">
                        <div class="card-body">
                            <div class="text-value">{{bages.direct}}</div>
                            <div>Number of direct report today</div>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/cardiologist/reports/pending')" class="col-sm-6 col-lg-3">
                    <div class="card text-white bg-primary">
                        <div class="card-body">
                            <div class="text-value">{{bages.open}}</div>
                            <div>Number of open report today</div>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/cardiologist/reports/completed')" class="col-sm-6 col-lg-3">
                    <div class="card text-white bg-warning">
                        <div class="card-body">
                            <div class="text-value">{{bages.completed}}</div>
                            <div>Number of completed report today</div>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/cardiologist/reports/review')" class="col-sm-6 col-lg-3">
                    <div class="card text-white bg-danger">
                        <div class="card-body">
                            <div class="text-value">{{bages.review}}</div>
                            <div>Number of review report today</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            bages: {},
        }
    },
    async created(){
        this.bages = await this.get("cardiologist/dashboard-bages");
    }
}
</script>
<style scoped>
.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}
.text-value {
    font-size: 1.3125rem;
    font-weight: 600;
}
</style>